<template>
  <div>
    <Pane />

    <div class="container">
      <a-form
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 12 }"
        :colon="false"
        @submit="handleSubmit"
        :form="form"
      >
        <a-form-item label="标题">
          <a-input
            v-decorator="[
              'title',
              { rules: [{ required: true, message: '请输入！' }] },
            ]"
          />
        </a-form-item>

        <a-form-item label="来源">
          <a-input
            v-decorator="[
              'source',
              { rules: [{ required: true, message: '请输入！' }] },
            ]"
          />
        </a-form-item>
             <a-form-item label="发布时间">
                    <a-date-picker
                placeholder=""
                v-decorator="[
                  'publishAt',
                  { rules: [{ required: true, message: '请选择！' }] },
                ]"
                style="width: 100%"
              />
       
        </a-form-item>

        

        <a-form-item>
          <span class="required" slot="label">正文</span>
          <div id="editor"></div>
        </a-form-item>

        <a-form-item>
          <span class="" slot="label">附件</span>
          <FileUpload :multiple="false" @uploaded="uploaded">
            <a-button type="primary">上传</a-button>
          </FileUpload>

          <Padding />

          <a-table bordered :data-source="fileList" :pagination="false">
            <a-table-column title="文件名">
              <template slot-scope="text">
                <a :href="text.completePath" target="_blank">{{
                  text.completePath
                }}</a>
              </template>
            </a-table-column>
            <a-table-column title="操作">
              <template slot-scope="text, record, index">
                <a href="#" class="danger" @click.prevent="remove(index)"
                  >删除</a
                >
              </template>
            </a-table-column>
          </a-table>
        </a-form-item>

        <div class="center">
          <a-space>
            <a-button htmlType="submit" type="primary">保存</a-button>
            <a-button @click="$close($route.path)">关闭</a-button>
          </a-space>
        </div>
      </a-form>
    </div>
  </div>
</template>

<script>
import E from "wangeditor";
import FileUpload from "@/components/file-upload";
import { add } from "@/api/oa/notice";

export default {
  name: "addNotice",

  components: {
    FileUpload,
  },

  data() {
    return {
      form: this.$form.createForm(this),
      editor: null,
      fileList: [],
    };
  },

  mounted() {
    const editor = new E("#editor");

    editor.config.height = 400;
    editor.config.zIndex = 1;
    editor.config.placeholder = "";
    editor.config.uploadImgServer = "/api/common-service/file/upload";
    editor.config.uploadImgHeaders = {
      token: window.localStorage.getItem("token"),
    };
    editor.config.uploadFileName = "files";
    editor.config.uploadImgHooks = {
      customInsert: function (insertImgFn, result) {
        if (result && Array.isArray(result.data) && result.data.length > 0) {
          insertImgFn(result.data[0].completePath);
        }
      },
    };
    editor.create();
    this.editor = editor;
  },

  beforeDestroy() {
    if (this.editor) {
      this.editor.destroy();
      this.editor = null;
    }
  },

  methods: {
    uploaded(list) {
      this.fileList = list;
    },
    remove(index) {
      this.fileList.splice(index, 1);
    },

    handleSubmit(e) {
      e.preventDefault();

      const content = this.editor.txt.html();

      if (content.length === 0) {
        this.$message.error("请输入正文！");
        return;
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          add({
            ...values,
            publishAt: values.publishAt.format("YYYY-MM-DD"),
            content,
            attachments: this.fileList
              .map((item) => item.completePath)
              .join(","),

          }).then(() => {
            this.$close(this.$route.path);
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  background-color: #fff;
  padding: 30px 164px;
}

.center {
  margin-top: 91px;
}
</style>
